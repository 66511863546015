<template>
  <div>
    <b-modal
      id="new-vendor-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="new-vendor-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('new-vendor-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New Partner</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit="createNewVendor" @reset="resetForm">
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="vendor-image-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Partner Logo</span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="vendorImage"
                id="vendor-image-input"
                class="mt-3"
                ref="vendorImageRef"
              />
            </div>
          </div>
          <div class="col-md-6 vendor-image-preview">
            <div class="image-block">
              <template v-if="imageUrl !== null">
                <img :src="imageUrl" alt="Level image" />
              </template>
              <template v-else>
                <h6>No image added</h6>
              </template>
            </div>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="vendor-background-image" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Background Image</span>
              </label>
              <input
                @change="uploadProfile"
                type="file"
                name="vendorProfileImage"
                id="vendor-background-image"
                class="mt-3"
                ref="vendorProfileImageRef"
              />
            </div>
          </div>
          <div class="col-md-6 vendor-image-preview">
            <div class="image-block">
              <template v-if="profileImage !== null">
                <img :src="profileImageUrl" alt="Profile image" />
              </template>
              <template v-else>
                <h6>No image added</h6>
              </template>
            </div>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-12">
            <b-form-checkbox
              id="is-contract-checkbox"
              v-model="form.isContract"
              name="is-contract-checkbox"
              :value="true"
              :unchecked-value="false"
            >
              Contracted Vendor
            </b-form-checkbox>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label class="input-label" for="store-name">Store Name</label>
            <b-form-input
              required
              v-model="form.storeName"
              placeholder="Store Name"
              @change="changeStoreName"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label class="input-label" for="phone">Phone</label>
            <b-form-input
              v-if="region === 'PAKISTAN'"
              disabledFetchingCountry
              :onlyCountries="['pk']"
              enabledCountryCode
              :enabledFlags="false"
              validCharactersOnly
              :maxLen="12"
              required
              defaultCountry="PK"
              v-model="form.phone"
            ></b-form-input>

            <vue-tel-input
              v-if="region === 'US'"
              disabledFetchingCountry
              :onlyCountries="['us']"
              enabledCountryCode
              :enabledFlags="false"
              validCharactersOnly
              :maxLen="10"
              required
              defaultCountry="US"
              v-model="phone"
              :disabled="!form.isContract"
            ></vue-tel-input>
          </div>
        </div>
        <div class="row py-">
          <div class="col-md-12">
            <label
              v-if="region === 'PAKISTAN'"
              class="input-label"
              for="owner-name"
              >Email for invoicing</label
            >
            <b-form-input
              v-if="region === 'PAKISTAN'"
              required
              v-model="form.email"
              type="email"
              placeholder="Email for invoicing"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label class="input-label" for="owner-name">Owner Name</label>
            <b-form-input
              required
              v-model="form.ownerName"
              placeholder="Owner Name"
            ></b-form-input>
          </div>
          <div v-if="region === 'PAKISTAN'" class="col-md-6">
            <label class="input-label" for="ssn">National Id</label>
            <b-form-input
              required
              v-model="form.cnic"
              type="text"
              pattern="\b\d{5}[-]\d{7}[-]\d{1}\b"
              placeholder="CNIC 12345-1234567-1"
              :disabled="!form.isContract"
            ></b-form-input>
          </div>
          <div v-if="region === 'US'" class="col-md-6">
            <label class="input-label" for="ssn">Social Security Number</label>
            <b-form-input
              required
              v-model="form.ssn"
              @keyup="ssnKey"
              type="text"
              pattern="\b\d{3}[-]\d{2}[-]\d{4}\b"
              placeholder="Social Security Number 123-12-1234"
              :disabled="!form.isContract"
            ></b-form-input>
          </div>
          <div v-if="region === 'US'" class="col-md-6">
            <label class="input-label" for="ssn">Accetable Distance</label>
            <b-form-input
              required
              v-model="form.acceptableRange"
              type="number"
              placeholder="Acceptable Range"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label class="input-label" for="address">Address</label>
            <ValidateAddress
              :addressPassed="form.address"
              @sendAddress="recieveAddress"
              ref="validateRef"
            />
          </div>
          <div class="col-md-6" v-if="region === 'PAKISTAN'">
            <label for="form-invoicing-options">Invoicing method</label>
            <b-form-select
              required
              v-model="form.invoicingMethod"
              :options="invoicingOptions"
            ></b-form-select>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label class="input-label" for="store-type">Business Line</label>
            <b-form-select v-model="form.businessLine" :options="businessLines">
            </b-form-select>
          </div>
          <div class="col-md-6" v-if="region === 'PAKISTAN'">
            <label class="input-label" for="store-type"
              >Operating margin on products</label
            >
            <b-form-input
              required
              type="number"
              placeholder="Operating margin on products"
              v-model="form.operatingMargin"
            ></b-form-input>
          </div>
        </div>
        <div v-if="region === 'PAKISTAN'" class="row py-4">
          <div class="col-md-4">
            <label for="delivery-charges-primary" class="input-label"
              >Primary zone delivery charges</label
            >
            <b-form-input
              type="number"
              placeholder="Delivery charges in primary zone"
              v-model="form.deliveryChargesSubzone"
              required
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="" class="input-label"
              >Secondary zone delivery charges</label
            >
            <b-form-input
              type="number"
              placeholder="Delivery charges in secondary zone"
              v-model="form.deliveryChargesZone"
              required
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="" class="input-label"
              >Other zones delivery charges</label
            >
            <b-form-input
              type="number"
              placeholder="Delivery charges in other zones"
              v-model="form.deliveryChargesMasterZone"
              required
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-12">
            <label class="input-label" for="store-type">Description</label>
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              placeholder="Description"
              rows="3"
              max-rows="6"
              e
            ></b-form-textarea>
          </div>
        </div>

        <div class="row p-4" v-if="form.isContract">
          <div class="image-upload">
            <label for="vendor-doc-image" class="image-upload-label">
              <span class="icon attachment">
                <i class="fa fa-paperclip"></i>
                <span class="text">Upload Attachment</span>
              </span>
            </label>
            <input
              @change="uploadAttach"
              type="file"
              name="docImage"
              id="vendor-doc-image"
              class="mt-3"
              ref="docImageRef"
              required
            />
          </div>
        </div>

        <div class="row d-flex justify-content-end p-4">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Create
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { GET_ALL_BUSINESS_LINES } from "@/core/services/store/businessLines.module";
import { mapState, mapGetters } from "vuex";
import ValidateAddress from "@/view/pages/riders/ValidateAddress";

export default {
  components: {
    ValidateAddress
  },
  async mounted() {
    await this.$store.dispatch(GET_ALL_BUSINESS_LINES);
  },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        address: "",
        storeName: "",
        ownerName: "",
        description: "",
        longitude: 0,
        latitude: 0,
        state: "MN",
        ssn: "",
        zipCode: "",
        businessLine: null,
        storeType: null,
        userType: "VENDOR",
        cnic: "",
        isContract: true,
        invoicingMethod: "Daily",
        operatingMargin: 15,
        deliveryChargesSubzone: 0,
        deliveryChargesZone: 0,
        deliveryChargesMasterZone: 0,
        acceptableRange: 0
      },
      vendorImage: null,
      imageUrl: null,
      invoicingOptions: ["Daily", "Weekly", "Fortnightly", "Monthly"],
      phone: "",
      profileImage: null,
      profileImageUrl: null,

      docImage: null,
      zipcodesOptions: [],
      selectedZipCode: null,
      processingRequest: "",
      hasError: false,
      processingError: ""
    };
  },

  methods: {
    ...mapGetters(["getAllBusinessLines"]),
    ssnKey: function() {
      const value = this.form.ssn;
      var val = value.replace(/\D/g, "");
      val = val.replace(/^(\d{3})/, "$1-");
      val = val.replace(/-(\d{2})/, "-$1-");
      val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
      this.form.ssn = val;
    },
    showModal: function() {
      this.$bvModal.show("new-vendor-modal");
    },
    closeModal: function() {
      this.$bvModal.hide("new-vendor-modal");
    },
    uploadFile: function() {
      this.vendorImage = this.$refs.vendorImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.vendorImage);
    },
    uploadProfile: function() {
      this.profileImage = this.$refs.vendorProfileImageRef.files[0];
      this.profileImageUrl = URL.createObjectURL(this.profileImage);
    },
    uploadAttach: function() {
      this.docImage = this.$refs.docImageRef.files[0];
      // this.imageUrl = URL.createObjectURL(this.vendorImage);
    },

    resetForm: function() {
      const defaultForm = {
        name: "",
        phone: "",
        address: "",
        storeName: "",
        isContract: false,
        ownerName: "",
        description: "",
        longitude: -71.5,
        latitude: 44.0,
        state: "MN",
        ssn: "",
        zipCode: "",
        businessLine: null,
        storeType: null,
        placeId: "",
        userType: "VENDOR",
        email: ""
      };
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }
      this.imageUrl = null;
    },
    beforeSubmission: function() {
      if (!this.form.isContract && this.region === "PAKISTAN") {
        // this.form.phone = this.generateRandomDigits();
        this.form.ssn = this.generateRandomDigits();
      }
      let newItem = this.businessLines.filter(
        item => item.value === this.form.businessLine
      );
      this.form.storeType = newItem[0].text;
    },
    recieveAddress: function(obj) {
      this.form.address = obj.address;
      this.form.zipCode = obj.zipCode;
      this.form.placeId = obj.placeId;
      this.form.latitude = obj.lat;
      this.form.longitude = obj.lng;
      console.log("address: ", this.form.address);
      console.log("zipcode: ", this.form.zipCode);
    },
    createNewVendor: async function(e) {
      e.preventDefault();

      if (this.region === "US") {
        const tk = JwtService.getToken();
        this.form.token = tk;
        this.form.phone = `+1${parseInt(
          this.phone
            .replace("(", "")
            .replace(")", "")
            .replace("-", "")
            .replace(" ", ""),
          10
        )}`;
        this.beforeSubmission();
        this.processingRequest = "processing";
        let newPassword = "";
        ApiService.post("/user/addUser", this.form)
          .then(res => {
            if (res.data.data.success) {
              console.log("res.data", res.data);
              newPassword = res.data.user.password;
              if (this.vendorImage !== null) {
                ApiService.setHeader("content-type", "multipart/form-data");
                const formData = new FormData();
                formData.append("image", this.vendorImage);
                formData.append("storeId", res.data.data.data._id);
                formData.append("token", `${tk}`);
                ApiService.post("/store/uploadImage", formData)
                  .then(res1 => {
                    if (res1.data.success === 0) {
                      this.hasError = "d-flex";
                      this.processingError = res1.data.message;
                      this.processingRequest = "";
                      setTimeout(() => {
                        this.hasError = "d-none";
                        this.processingError = "";
                      }, 3000);
                      return;
                    }
                    if (this.profileImage !== null) {
                      ApiService.setHeader(
                        "content-type",
                        "multipart/form-data"
                      );
                      const formData2 = new FormData();
                      formData2.append("image", this.profileImage);
                      formData2.append("storeId", res.data.data.data._id);
                      formData2.append("token", `${tk}`);
                      ApiService.post("/store/updateProfilePicture", formData2)
                        .then(res2 => {
                          ApiService.setHeader(
                            "content-type",
                            "application/json"
                          );
                          if (res2.data.success === 0) {
                            this.hasError = "d-flex";
                            this.processingError = res2.data.message;
                            this.processingRequest = "";
                            setTimeout(() => {
                              this.hasError = "d-none";
                              this.processingError = "";
                            }, 3000);
                            return;
                          }
                          this.resetForm();
                          this.$emit("addedNewVendor", newPassword);
                          this.processingRequest = "";
                        })
                        .catch(() => {
                          this.processingRequest = "";
                          ApiService.setHeader(
                            "content-type",
                            "application/json"
                          );
                        });
                    } else {
                      ApiService.setHeader("content-type", "application/json");
                      this.resetForm();
                      this.$emit("addedNewVendor", newPassword);
                      this.processingRequest = "";
                    }
                  })
                  .catch(() => {
                    this.processingRequest = "";
                    ApiService.setHeader("content-type", "application/json");
                  });
              } else {
                ApiService.setHeader("content-type", "application/json");
                this.resetForm();
                this.$emit("addedNewVendor", newPassword);
                this.processingRequest = "";
              }
            }
          })
          .catch(({ data }) => {
            ApiService.setHeader("content-type", "application/json");
            this.hasError = "d-flex";
            this.processingError = data.message + " on " + data.url;
            this.processingStatusText = this.processingError;
            this.processingRequest = "";
            setTimeout(() => {
              this.hasError = "d-none";
              this.processingError = "";
            }, 3000);
          });
      } else {
        this.beforeSubmission();
        this.processingRequest = "processing";
        ApiService.post("/user/addUser", this.form)
          .then(({ data: res }) => {
            console.log(".then | res", res);
            if (res.success) {
              if (this.vendorImage !== null) {
                ApiService.setHeader("content-type", "multipart/form-data");
                const formData = new FormData();
                formData.append("image", this.vendorImage);
                formData.append("storeId", res.data._id);
                const pass = res.user.password;
                ApiService.post("/store/uploadImage", formData)
                  .then(res1 => {
                    if (res1.data.success === 0) {
                      this.hasError = "d-flex";
                      this.processingError = res1.data.message;
                      this.processingRequest = "";
                      setTimeout(() => {
                        this.hasError = "d-none";
                        this.processingError = "";
                      }, 3000);
                      return;
                    }
                    if (this.profileImage !== null) {
                      ApiService.setHeader(
                        "content-type",
                        "multipart/form-data"
                      );
                      const formData2 = new FormData();
                      formData2.append("image", this.profileImage);
                      formData2.append("storeId", res.data._id);

                      ApiService.post("/store/updateProfilePicture", formData2)
                        .then(res2 => {
                          ApiService.setHeader(
                            "content-type",
                            "application/json"
                          );
                          if (res2.data.success === 0) {
                            this.hasError = "d-flex";
                            this.processingError = res2.data.message;
                            this.processingRequest = "";
                            setTimeout(() => {
                              this.hasError = "d-none";
                              this.processingError = "";
                            }, 3000);
                            return;
                          }
                          this.resetForm();
                          this.processingRequest = "";
                          this.$emit("addedNewVendor", pass);
                        })
                        .catch(() => {
                          this.processingRequest = "";
                          ApiService.setHeader(
                            "content-type",
                            "application/json"
                          );
                        });
                    } else {
                      ApiService.setHeader("content-type", "application/json");
                      this.resetForm();
                      this.$emit("addedNewVendor");
                      this.processingRequest = "";
                    }
                  })
                  .catch(() => {
                    this.processingRequest = "";
                    ApiService.setHeader("content-type", "application/json");
                  });
              } else {
                ApiService.setHeader("content-type", "application/json");
                this.resetForm();
                this.$emit("addedNewVendor");
                this.processingRequest = "";
              }
            } else {
              this.hasError = "d-flex";
              this.processingError = res.data;
              this.processingRequest = "";
              setTimeout(() => {
                this.hasError = "d-none";
                this.processingError = "";
              }, 3000);
              return;
            }
          })
          .catch(errorHere => {
            console.log("createNewVendor:function | errorHere", errorHere);
            const { data } = errorHere;
            ApiService.setHeader("content-type", "application/json");
            this.hasError = "d-flex";
            this.processingError = data.message + " on " + data.url;
            this.processingStatusText = this.processingError;
            this.processingRequest = "";
            setTimeout(() => {
              this.hasError = "d-none";
              this.processingError = "";
            }, 3000);
          });
      }
    },
    changeStoreName: function(val) {
      this.form.name = val;
    },
    generateRandomDigits: function() {
      return "98" + (Math.floor(Math.random() * 90000000) + 10000000);
    }
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion,
      businessLines: state => {
        const arr = [];
        arr.push({ text: "Business Lines", value: null });
        state.businessLines.allBusinessLines.map(item => {
          if (state.region.isRegion === "US") {
            if (item.name.toLowerCase() !== "grocery")
              arr.push({ text: item.name, value: item._id });
          } else {
            arr.push({ text: item.name, value: item._id });
          }
        });
        return arr;
      }
    })
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .new-vendor-modal {
  background: #fff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;

    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .image-upload {
    position: relative;
    label {
      display: flex;
      align-items: center;

      span.icon {
        &.attachment {
          display: flex;
          .text {
            margin-left: 10px;
            max-width: 170px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        font-weight: 700;
        font-weight: 12px;
        display: flex;
        align-items: center;
      }
      margin: 0;
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
  .vendor-image-preview {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h6 {
      font-size: 10px;
      text-align: center;
    }
    .image-block {
      border-radius: 100%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .input-label {
    font-weight: 600;
  }
}
</style>
