<template>
  <div>
    <b-modal
      id="edit-vendor-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="edit-vendor-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('edit-vendor-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Update Partner</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit="createNewVendor" @reset="resetForm">
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="vendor-image-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Partner Image</span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="vendorImage"
                id="vendor-image-input"
                class="mt-3"
                ref="vendorImageRef"
              />
            </div>
          </div>
          <div class="col-md-6 vendor-image-preview">
            <div class="image-block">
              <template v-if="imageUrl !== null">
                <img :src="imageUrl" alt="Vendor image" />
              </template>
              <template
                v-else-if="vendorToEdit !== null && vendorToEdit.storeLogo"
              >
                <!-- why is !=  instead of !== -->
                <StackedImages
                  :images="[vendorToEdit.storeLogo]"
                  type="product"
                />
              </template>
              <template
                v-else-if="
                  vendorToEdit !== null && vendorToEdit.storeLogo === undefined
                "
              >
                <h6>No image added</h6>
              </template>
            </div>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="vendor-image-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Profile Image</span>
              </label>
              <input
                @change="uploadProfile"
                type="file"
                name="vendorProfileImage"
                id="vendor-background-image"
                class="mt-3"
                ref="vendorProfileImageRef"
              />
            </div>
          </div>
          <div class="col-md-6 vendor-image-preview">
            <div class="image-block">
              <template v-if="profileImageUrl !== null">
                <img :src="profileImageUrl" alt="Vendor image" />
              </template>
              <template
                v-else-if="vendorToEdit !== null && vendorToEdit.profileImage"
              >
                <StackedImages
                  :images="[vendorToEdit.profileImage]"
                  type="product"
                />
              </template>
              <template
                v-else-if="
                  vendorToEdit !== null &&
                    vendorToEdit.profileImage === undefined
                "
              >
                <h6>No image added</h6>
              </template>
            </div>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-12">
            <b-form-checkbox
              id="is-contract-checkbox"
              v-model="form.isContract"
              name="is-contract-checkbox"
              :value="true"
              :unchecked-value="false"
            >
              Contracted Partner?
            </b-form-checkbox>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label class="input-label" for="store-name">Store Name</label>
            <b-form-input
              required
              v-model="form.storeName"
              placeholder="Store Name"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label class="input-label" for="phone">Phone</label>
            <b-form-input
              v-if="region === 'PAKISTAN'"
              required
              type="text"
              pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
              v-model="form.phone"
              placeholder="Phone#"
              :disabled="!form.isContract"
            ></b-form-input>
            <vue-tel-input
              v-if="region === 'US'"
              disabledFetchingCountry
              :onlyCountries="['us']"
              enabledCountryCode
              :enabledFlags="false"
              validCharactersOnly
              :maxLen="10"
              required
              autoFormat="false"
              defaultCountry="US"
              v-model="form.phone"
              :disabled="!form.isContract"
            ></vue-tel-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label class="input-label" for="owner-name">Owner Name</label>
            <b-form-input
              aria-invalid=""
              v-model="form.ownerName"
              placeholder="Owner Name"
            ></b-form-input>
          </div>
          <div class="col-md-6" v-if="region === 'PAKISTAN'">
            <label class="input-label" for="ssn">National Id</label>
            <b-form-input
              v-model="form.cnic"
              type="text"
              pattern="\b\d{5}[-]\d{7}[-]\d{1}\b"
              placeholder="CNIC 12345-1234567-1"
              :disabled="!form.isContract"
            ></b-form-input>
          </div>

          <div class="col-md-6" v-if="region === 'US'">
            <label class="input-label" for="ssn">Social Security Number</label>
            <b-form-input
              required
              v-model="form.ssn"
              @keyup="ssnKey"
              type="text"
              pattern="\b\d{3}[-]\d{2}[-]\d{4}\b"
              placeholder="Social Security Number 123-12-1234"
              :disabled="!form.isContract"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label class="input-label" for="address">Address</label>
            <ValidateAddress
              :addressPassed="form.address"
              @sendAddress="recieveAddress"
              ref="validateRef"
            />
          </div>
          <div class="col-md-6" v-if="region === 'PAKISTAN'">
            <label for="form-invoicing-options">Invoicing method</label>
            <b-form-select
              required
              v-model="form.invoicingMethod"
              :options="invoicingOptions"
            ></b-form-select>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label
              v-if="region === 'PAKISTAN'"
              class="input-label"
              for="store-type"
              >Business Line
            </label>
            <b-form-select
              v-model="form.businessLine"
              @change="changeLine"
              :options="businessLines"
              :disabled="
                vendorToEdit.products.length > 0 ||
                  vendorToEdit.subCategories.length > 0
              "
            >
              <!-- how did businessLine get name from Id -->
            </b-form-select>
          </div>
          <div class="col-md-6" v-if="region === 'PAKISTAN'">
            <label class="input-label" for="store-type"
              >Operating margin on products
            </label>
            <b-form-input
              required
              type="number"
              v-model="form.operatingMargin"
            ></b-form-input>
          </div>
        </div>
        <div v-if="region === 'PAKISTAN'" class="row py-4">
          <div class="col-md-4">
            <label for="delivery-charges-primary" class="input-label"
              >Primary zone delivery charges</label
            >
            <b-form-input
              type="number"
              placeholder="Delivery charges in primary zone"
              v-model="form.deliveryChargesPzone"
              required
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="" class="input-label"
              >Secondary zone delivery charges</label
            >
            <b-form-input
              type="number"
              placeholder="Delivery charges in secondary zone"
              v-model="form.deliveryChargesSZone"
              required
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="" class="input-label"
              >Other zones delivery charges</label
            >
            <b-form-input
              type="number"
              placeholder="Delivery charges in other zones"
              v-model="form.deliveryChargesOZone"
              required
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-12">
            <label class="input-label" for="store-type">Description</label>
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              placeholder="Description"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </div>

        <div class="row p-4" v-if="form.isContract">
          <div class="image-upload">
            <label for="vendor-doc-image" class="image-upload-label">
              <span class="icon attachment">
                <i class="fa fa-paperclip"></i>
                <span class="text">Upload Attachment</span>
              </span>
            </label>
            <input
              @change="uploadAttach"
              type="file"
              name="docImage"
              id="vendor-doc-image"
              class="mt-3"
              ref="docImageRef"
            />
          </div>
        </div>
        <div class="row d-flex justify-content-end p-4">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Update
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { GET_ALL_BUSINESS_LINES } from "@/core/services/store/businessLines.module";
import { mapState, mapGetters } from "vuex";
import ValidateAddress from "@/view/pages/riders/ValidateAddress";
import StackedImages from "@/view/layout/dashboard_components/StackedImages";

export default {
  components: {
    StackedImages,
    ValidateAddress
  },
  mounted() {
    this.$store.dispatch(GET_ALL_BUSINESS_LINES);
    const val = this.vendorToEdit;
    console.log(val);
    for (let key of Object.keys(this.form)) {
      this.form[key] = val[key];
    }
    this.form.storeName = val.storeName;
    this.form.userType = "VENDOR";
    this.form.businessLine = val.businessLine._id;
    this.form.storeType = val.storeType;
    this.form.address = val.storeAddress.address;
    this.form.ownerName = val.owner.name;
    this.form.cnic = val.owner.cnic;

    this.form.storeId = val._id;
    this.form.zipCode = val.zipCode;
    if (this.region === "PAKISTAN") {
      this.form.invoicingMethod = val.invoicingMethod;
      this.form.operatingMargin = val.operatingMargin;
      this.form.deliveryChargesPzone = val.deliveryCharges.primaryZone;
      this.form.deliveryChargesSZone = val.deliveryCharges.secondaryZone;
      this.form.deliveryChargesOZone = val.deliveryCharges.others;
      this.form.cnic = val.owner.cnic;
    } else {
      this.form.isContract = val.isContract;
      this.form.phone = val.phone.replace("+1", " ");
    }

    if (!this.form.isContract) {
      if (this.region === "PAKISTAN") {
        this.form.cnic = "";
      } else {
        this.form.ssn = "";
      }
      if (this.region === "PAKISTAN") {
        this.phone = val.phone;
      }
    }
    this.profileImageUrl = val.profileImage;
    this.form.longitude = val.storeAddress.coordinates[0];
    this.form.latitude = val.storeAddress.coordinates[1];
  },
  props: {
    vendorToEdit: Object
  },
  data() {
    return {
      form: {
        phone: "",
        address: "",
        storeName: "",
        ownerName: "",
        isContract: false,
        description: "",
        longitude: -71.5,
        latitude: 44.0,
        state: "MN",
        cnic: "",
        ssn: "",
        zipCode: "",
        businessLine: null,
        storeType: null,
        userType: "VENDOR",
        invoicingMethod: "",
        operatingMargin: "",
        deliveryChargesPzone: "",
        deliveryChargesSzone: "",
        deliveryChargesOzone: ""
      },
      invoicingOptions: ["Daily", "Weekly", "Fortnightly", "Monthly"],
      vendorImage: null,
      imageUrl: null,
      profileImage: null,
      phone: "",
      profileImageUrl: null,

      selectedZipCode: null,
      processingRequest: "",
      hasError: false,
      processingError: ""
    };
  },

  methods: {
    ...mapGetters(["getAllBusinessLines"]),
    showModal: function() {
      this.$bvModal.show("edit-vendor-modal");
    },
    ssnKey: function() {
      const value = this.form.ssn;
      var val = value.replace(/\D/g, "");
      val = val.replace(/^(\d{3})/, "$1-");
      val = val.replace(/-(\d{2})/, "-$1-");
      val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
      this.form.ssn = val;
    },
    closeModal: function() {
      this.$bvModal.hide("edit-vendor-modal");
    },
    uploadFile: function() {
      this.vendorImage = this.$refs.vendorImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.vendorImage);
    },

    uploadProfile: function() {
      this.profileImage = this.$refs.vendorProfileImageRef.files[0];
      this.profileImageUrl = URL.createObjectURL(this.profileImage);
    },
    uploadAttach: function() {
      this.docImage = this.$refs.docImageRef.files[0];
    },
    changeLine: function(val) {
      console.log("val", val);
      if (val !== null) {
        this.form.storeType = this.businessLines.find(
          b => b.value === val
        ).text;
      }
    },
    // changeinvoiceMethod:function (val) {
    //   console.log(val);
    //   console.log(this.form.invoicingMethod)
    //   if(val !== null){
    //     //this.form.invoicingMethod= val;
    //  return
    //  }
    // },
    resetForm: function() {
      const defaultForm = {
        phone: "",
        storeName: "",
        ownerName: "",
        description: "",
        isContract: false,
        state: "MN",
        cnic: "",
        ssn: "",
        businessLine: null,
        storeType: null,
        userType: "VENDOR",
        invoicingMethod: "",
        operatingMargin: "",
        deliveryChargesPzone: "",
        deliveryChargesSzone: "",
        deliveryChargesOzone: "",

        address: "",
        zipCode: "",
        longitude: "",
        latitude: "",
        placeId: ""
      };
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }

      this.imageUrl = null;
      this.$emit("resetRider");
    },
    beforeSubmission: function() {
      if (this.region === "PAKISTAN") {
        if (!this.form.isContract) {
          this.form.phone = this.form.phone
            ? this.form.phone
            : this.generateRandomDigits();
          this.form.ssn = this.form.ssn
            ? this.generateRandomDigits()
            : this.form.ssn;
        }
      }

      // I dont know if there should be 1 "=" sign in if condition
      let newItem = this.businessLines.filter(
        item => item.value === this.form.businessLine
      );
      this.form.storeType = newItem[0].text;
    },
    recieveAddress: function(obj) {
      this.form.address = obj.address;
      this.form.zipCode = obj.zipCode;
      this.form.placeId = obj.placeId;
      this.form.latitude = obj.lat;
      this.form.longitude = obj.lng;
    },
    generateRandomDigits: function() {
      return "98" + (Math.floor(Math.random() * 90000000) + 10000000);
    },
    createNewVendor: async function(e) {
      e.preventDefault();
      const tk = JwtService.getToken();
      this.form.token = tk;

      this.beforeSubmission();
      this.processingRequest = "processing";
      ApiService.post("/store/editStore", this.form)
        .then(res => {
          if (res.data.success) {
            if (this.vendorImage !== null) {
              ApiService.setHeader("content-type", "multipart/form-data");
              const formData = new FormData();
              formData.append("image", this.vendorImage);
              formData.append("storeId", this.form.storeId);
              formData.append("token", `${tk}`);
              ApiService.post("/store/uploadImage", formData)
                .then(() => {
                  if (this.profileImage !== null) {
                    this.uploadProfileImage(res.data.data._id, tk);
                  } else {
                    ApiService.setHeader("content-type", "application/json");
                    this.resetForm();
                    this.$emit("editedVendor");
                    this.processingRequest = "";
                  }
                })
                .catch(({ data }) => {
                  this.hasError = "d-flex";
                  this.processingError =
                    "Error while uploading new image.<br/>" +
                    data.message +
                    " on " +
                    data.url;
                  this.processingRequest = "";
                  setTimeout(() => {
                    this.hasError = "d-none";
                    this.processingError = "";
                  }, 3000);
                });
            } else {
              if (this.profileImage !== null) {
                this.uploadProfileImage(res.data.data._id, tk);
              } else {
                ApiService.setHeader("content-type", "application/json");
                this.resetForm();
                this.$emit("editedVendor");
                this.processingRequest = "";
              }
            }
          } else {
            this.hasError = "d-flex";
            this.processingError = "Unknown error while updating vendor.";
            setTimeout(() => {
              this.hasError = "d-none";
              this.processingError = "";
            }, 3000);
          }
        })
        .catch(({ data }) => {
          this.hasError = "d-flex";
          this.processingError =
            "Error while updating user info.<br/>" +
            data.message +
            " on " +
            data.url;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
        })
        .catch(() => {
          this.processingRequest = "";
          ApiService.setHeader("content-type", "application/json");
        });
    },
    uploadProfileImage: function(storeId, tk) {
      ApiService.setHeader("content-type", "multipart/form-data");
      const formData2 = new FormData();
      formData2.append("image", this.profileImage);
      formData2.append("storeId", storeId);
      formData2.append("token", `${tk}`);
      ApiService.post("/store/updateProfilePicture", formData2)
        .then(res2 => {
          ApiService.setHeader("content-type", "application/json");
          if (res2.data.success === 0) {
            this.hasError = "d-flex";
            this.processingError = res2.data.message;
            this.processingRequest = "";
            setTimeout(() => {
              this.hasError = "d-none";
              this.processingError = "";
            }, 3000);
            return;
          }
          this.resetForm();
          this.$emit("editedVendor");
          this.processingRequest = "";
        })
        .catch(() => {
          this.processingRequest = "";
          ApiService.setHeader("content-type", "application/json");
        });
    }
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion,
      businessLines: state => {
        const arr = [];
        arr.push({ text: "Business Lines", value: null });
        state.businessLines.allBusinessLines.map(item => {
          arr.push({ text: item.name, value: item._id });
        });
        return arr;
      }
    })
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .edit-vendor-modal {
  background: #fff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;

    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .vendor-image-preview {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h6 {
      font-size: 10px;
      text-align: center;
    }
    .image-block {
      border-radius: 100%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .input-label {
    font-weight: 600;
  }
  .image-upload {
    position: relative;
    label {
      display: flex;
      align-items: center;

      span.icon {
        &.attachment {
          display: flex;
          .text {
            margin-left: 10px;
            max-width: 170px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        font-weight: 700;
        font-weight: 12px;
        display: flex;
        align-items: center;
      }
      margin: 0;
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
  /* .form-group {
    .form-check {
    }
  } */
}
</style>
