import { render, staticRenderFns } from "./FetchVendors.vue?vue&type=template&id=312cc559&scoped=true&"
import script from "./FetchVendors.vue?vue&type=script&lang=js&"
export * from "./FetchVendors.vue?vue&type=script&lang=js&"
import style0 from "./FetchVendors.vue?vue&type=style&index=0&id=312cc559&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "312cc559",
  null
  
)

export default component.exports