<template>
  <div class="fetch-vendors">
    <div class="row">
      <div class="col-lg-6 py-3">
        <h3>Fetch Partners</h3>
      </div>
    </div>
    <div class="row d-flex align-items-center justify-content-center">
      <b-form @submit.prevent="fetchStores">
        <b-row class="my-1">
          <b-col sm="3" class="d-flex align-items-center">
            <label for="zipcode">Zipcode</label>
          </b-col>
          <b-col sm="6">
            <b-form-input
              id="zipcode"
              type="number"
              v-model="zipCode"
              :disabled="fetching === 'fetching'"
              :required="true"
            ></b-form-input>
          </b-col>
          <b-col sm="3">
            <b-button
              type="submit"
              variant="danger"
              :disabled="fetching === 'fetching'"
              >Search</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-overlay :show="fetching === 'fetching'">
      <template #overlay>
        <div class="w-100 h-100" style="display: grid; place-items: center">
          <img
            src="/media/ajax/white.gif"
            alt="Ajax loader"
            style="height: 40px; width: 40px"
          />
          <div
            class="d-flex flex-column align-items-center"
            v-html="loaderContent"
          ></div>
        </div>
      </template>
      <div class="row fetch-vendors__stores-list">
        <template>
          <div class="col-12">
            <template v-if="fetching === 'fetched' && results.length > 0">
              <h4 class="select-title py-3">
                {{ errorMessage }}
              </h4>
              <div class="row">
                <div class="col-12">
                  <b-checkbox
                    id="select-all"
                    class="my-4"
                    v-model="selectAll"
                    :value="true"
                    :unchecked-value="false"
                    @change="selectAllStores"
                  >
                    Select All ({{ results.length }})
                  </b-checkbox>
                </div>
              </div>
              <b-list-group
                v-for="(item, index) in results"
                :key="item.place_id"
              >
                <template v-if="item">
                  <b-list-group-item
                    class="p-4 mb-3 fetch-vendors__clickable"
                    @click="toggleSelected(index)"
                  >
                    <b-row>
                      <b-col cols="1" class="d-flex align-items-center">
                        <b-checkbox
                          :key="item.selected"
                          :id="`${item.placeId}`"
                          v-model="selected"
                          name=""
                          :value="index"
                        ></b-checkbox>
                      </b-col>
                      <b-col
                        cols="1"
                        class="d-flex align-items-center justify-content-center"
                      >
                        <img
                          :src="item.icon"
                          alt="item.name"
                          style="width: 40px; height: 40px"
                        />
                      </b-col>
                      <b-col cols="10">
                        <h3 class="text">
                          {{ item.name }}
                          <span
                            class="fetch-vendors__business-text"
                            :class="
                              `fetch-vendors__business-text--${item.businessLine}`
                            "
                          >
                            {{ item.businessLine }}
                          </span>
                          <span class="fetch-vendors__business-text">
                            {{ item.type }}
                          </span>
                        </h3>
                        <div class="text">{{ item.address }}</div>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </template>
              </b-list-group>
              <div
                class="w-100 d-flex justify-content-end py-3"
                style="
                  position: sticky;
                  bottom: 0;
                  left: 0;
                  background: rgba(255, 255, 255, 1);
                "
              >
                <b-button
                  variant="danger"
                  class="fetch-vendors__btn"
                  @click="clearList"
                  :disabled="selected.length < 1 || fetching !== 'fetched'"
                  >Remove Selected from list</b-button
                >
                <b-button
                  variant="danger"
                  class="fetch-vendors__btn"
                  @click="saveList"
                  :disabled="selected.length < 1 || fetching !== 'fetched'"
                  >Add to database</b-button
                >
              </div>
            </template>
            <template
              v-else-if="fetching === 'fetched' && results.length === 0"
            >
              {{ errorMessage }}
            </template>
          </div>
        </template>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
export default {
  data() {
    return {
      fetching: "not-fetching",
      zipCode: "",
      results: [],
      selected: [],
      selectAll: false,
      errorMessage: "",
      loaderContent: ""
    };
  },
  methods: {
    fetchStores: function(e) {
      e.preventDefault();
      this.fetching = "fetching";
      this.loaderContent = ` <span>Looking for Stores in ${this.zipCode}.</span><span>This may take a while</span>`;
      ApiService.post("/store/storesFromGoogle", { zipCode: `${this.zipCode}` })
        .then(({ data }) => {
          this.fetching = "fetched";
          if (data.success) {
            this.results = data.data.result;
            this.selectAll = true;
            this.errorMessage = data.message;
            this.selectAllStores(true);
          } else {
            this.errorMessage = data.error.message;
            this.results = [];
            this.selectAllStores(false);
            this.selectAll = false;
            // console.log("Error: ", error);
          }
        })
        .catch(error => {
          console.log(`error`, error);
          this.fetching = "fetched";
          this.errorMessage = error.message;
          console.log("Error: ", error);
        });
    },
    toggleSelected: function(index) {
      const q = this.selected.indexOf(index);
      if (q > -1) {
        this.results[index].selected = "not-selected";
        this.selected.splice(q, 1);
      } else {
        this.results[index].selected = "selected";
        this.selected.push(index);
      }
      if (this.results.length !== this.selected.length) {
        this.selectAll = false;
      } else {
        this.selectAll = true;
      }
    },
    clearList: function() {
      for (let index = 0; index < this.results.length; index += 1) {
        const q = this.selected.indexOf(index);
        if (q > -1 && this.results[index].selected === "selected") {
          this.selected.splice(q, 1);
        }
      }
      this.results = this.results.filter(
        item => item.selected === "not-selected"
      );
    },
    selectAllStores: function(val) {
      if (val === false) {
        if (this.results.length > 0) {
          // let index = 0;
          this.results.forEach(item => {
            item.selected = "not-selected";
          });
        }
        this.selected = [];
      } else {
        if (this.results.length > 0) {
          let index = 0;
          this.results.forEach(item => {
            item.selected = "selected";
            this.selected.push(index++);
          });
        }
      }
    },
    saveList: async function() {
      const storesList = this.results.filter(
        store => store.selected === "selected"
      );
      if (storesList.length < 1) {
        const temp = this.errorMessage;
        this.errorMessage = "Please Select at least 1 Store";
        setTimeout(() => {
          this.errorMessage = temp;
        }, 1000);
      }
      this.fetching = "fetching";
      this.loaderContent = `Saving ${storesList.length} store${
        storesList.length > 1 ? "s" : ""
      }.`;
      ApiService.post("/store/createStoresFromGoogle", { storesList })
        .then(({ data }) => {
          this.fetching = "fetched";
          this.errorMessage = data.message;
          this.results = this.results.filter(
            store => store.selected !== "selected"
          );
          this.selectAllStores(this.results.length > 0 ? true : false);
          this.selectAll = this.results.length > 0 ? true : false;
        })
        .catch(({ data }) => {
          this.fetching = "fetched";
          this.results = [];
          this.selectAllStores(false);
          this.selectAll = false;
          this.errorMessage = data.message;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.fetch-vendors {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: space-evenly;
  &__clickable {
    cursor: pointer;
  }
  &__stores-list {
    min-height: 100px;

    & > div {
      transition: height 1s;
    }
  }
  &__btn {
    margin-left: 10px;
  }
  &__business-text {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 2.5px 10px;
    font-size: 12px;
    margin-left: 10px;
    border: 1px solid #333;
    &--Food {
      border: 1px solid blue;
    }
    &--Grocery {
      border: 1px solid red;
    }
  }
  label {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
